import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Meta from "../components/Meta"

import GalleryTabs from "../components/gallery/GalleryTabs"
import GalleryImages from "../components/gallery/GalleryImages"
import Pagination from "../components/Pagination"

import PageTitle from "../components/ui/PageTitle"

import { formatTitle } from "../utils/helpers"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import MobileMenu from "../components/MobileMenu"

export default function GalleryPage({ data, pageContext }) {
  const gallery = data.gallery && data.gallery.nodes,
    details = data.sanityDetails,
    pageDetails = data.sanityGalleryDetails

  return (
    <div className="page">
      <Meta title={`Gallery - ${formatTitle(pageContext.category)}`} />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={pageDetails.pageTitle} />
          <GalleryTabs />
          <GalleryImages gallery={gallery} />
          <Pagination
            pageSize={pageContext.pageSize}
            totalCount={pageContext.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base={`/gallery/${pageContext.category}`}
          />
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const galleryPhotos = graphql`
  query GalleryQuery($category: [String], $skip: Int = 0, $pageSize: Int = 8) {
    gallery: allSanityGallery(
      filter: { category: { in: $category } }
      limit: $pageSize
      skip: $skip
    ) {
      nodes {
        image {
          ...ImageWithPreview
        }
        description
        category
      }
    }
    sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
    }
    sanityGalleryDetails {
      pageTitle
    }
  }
`
