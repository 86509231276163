import React from "react"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

const StyledImages = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 4rem;
  min-height: 65vh;
  @media (max-width: 1000px) {
    min-height: unset;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  li {
    width: 100%;
    transition: 0.3s ease-in;
    box-shadow: rgb(0 0 0 / 11%) 0px 0px 10px;
    &:hover {
      transform: scale(1.1);
      cursor: crosshair;
      @media (max-width: 1000px) {
        cursor: default;
        transform: unset;
      }
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 450px;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      @media (max-width: 1000px) {
        max-height: unset;
      }
    }
  }
`

export default function GalleryImages({ gallery }) {
  // call gallery/$category to get a category - gallery/studio

  const displayedGallery = gallery.map((item, index) => {
    return (
      <li key={index}>
        {item.image && (
          <Image
            title={item.description}
            alt={item.description || "dark-spectrum-image"}
            {...item.image}
            width={300}
            height={400}
          />
        )}
      </li>
    )
  })

  return <StyledImages>{displayedGallery}</StyledImages>
}
