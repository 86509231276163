import React from "react"
import styled from "styled-components"

const StyledTitle = styled.h1`
  color: var(--white);
  margin: 30px 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 18px;
  font-size: 30px;
  line-height: 1.5;
  @media (max-width: 600px) {
    font-size: 22px;
    letter-spacing: 8px;
  }
`

const PageTitle = ({ title }) => {
  if (!title) {
    return <></>
  }

  return <StyledTitle className="page-title">{title}</StyledTitle>
}

export default PageTitle
