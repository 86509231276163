import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import categories from "../../../categories.json"

const StyledTabs = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px 0 10px 0;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  li {
    padding-left: 80px;
    padding-bottom: 30px;
    white-space: nowrap;
    @media (max-width: 768px) {
      padding: 0;
      text-align: center;
      line-height: 50px;
    }
    &:first-of-type {
      padding-left: 0;
    }
    a {
      text-transform: uppercase;
      color: var(--white);
      font-weight: 600;
      transition: 0.2s ease-in;
      @media (max-width: 768px) {
        padding: 20px;
      }
      &.active-link {
        color: var(--red);
      }
      &:hover {
        color: var(--red);
      }
    }
  }
`

export default function GalleryTabs() {
  const location = useLocation()

  const catgs = categories && categories.categories

  const displayedTabs = catgs.map((cat, index) => {
    const path = location.pathname
    let isActive = false
    // fix for active tab
    if (path.indexOf(cat.value) !== -1) {
      isActive = true
    }

    return (
      <li key={index}>
        <Link
          to={`/gallery/${cat.value}/1`}
          className={isActive ? "active-link" : ""}
        >
          {cat.title}
        </Link>
      </li>
    )
  })

  return <StyledTabs>{displayedTabs}</StyledTabs>
}
