import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { FiChevronRight } from "react-icons/fi"
import { FiChevronLeft } from "react-icons/fi"

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;

  .page-nr {
    color: var(--white);
    transition: 0.2s ease-in;
    padding: 6px;
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      color: var(--red);
    }
    &.disabled-link {
      pointer-events: none;
      color: #71797e;
      user-select: none;
    }
    &.current-page {
      color: var(--red);
      font-size: 1.9rem;
    }
    &__next,
    &__prev {
      display: flex;
      align-items: center;
    }
  }
`

export default function Pagination({ totalCount, currentPage, base }) {
  const prevPage = currentPage - 1,
    nextPage = currentPage + 1,
    hasNextPage = nextPage <= totalCount,
    hasPrevPage = prevPage >= 1

  const disabledPrev = hasPrevPage ? "" : "disabled-link"
  const disabledNext = hasNextPage ? "" : "disabled-link"

  const pages = Array.from({ length: totalCount }).map((_, i) => (
    <Link
      to={`${base}/${i + 1}`}
      key={i}
      className={`page-nr page-nr__${i + 1} ${
        i + 1 === currentPage ? "current-page" : ""
      }`}
    >
      {i + 1}
    </Link>
  ))

  return (
    <StyledPagination>
      <Link
        to={`${base}/${prevPage}`}
        className={`page-nr page-nr__prev ${disabledPrev}`}
      >
        <FiChevronLeft /> Prev
      </Link>
      {pages}
      <Link
        to={`${base}/${nextPage}`}
        className={`page-nr page-nr__next ${disabledNext}`}
      >
        Next <FiChevronRight />
      </Link>
    </StyledPagination>
  )
}
